
/* carousel */
@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Akaya+Kanadaka&family=Itim&family=Merienda:wght@300..900&family=Nunito:ital,wght@1,200..1000&family=Raleway:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Itim&family=Nunito:ital,wght@1,200..1000&family=Raleway:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
.carousel{
    width: 100%;
    height: 96vh;
    margin-top: -50px;
    overflow: hidden;
    position: relative;
}

.carousel .list .item{
    width: 180px;
    height: 250px;
    position: absolute;
    top: 80%;
    transform: translateY(-70%);
    left: 70%;
    border-radius: 20px;
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
    background-position: 50% 50%;
    background-size: cover;
    z-index: 100;
    transition: 1s;
}

.carousel .list .item:nth-child(1),
.carousel .list .item:nth-child(2){
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
}

.carousel .list .item:nth-child(3){
    left: 67%;
}

.carousel .list .item:nth-child(4){
    left: calc(67% + 200px);
}

.carousel .list .item:nth-child(5){
    left: calc(67% + 400px);
}

.carousel .list .item:nth-child(6){
    left: calc(67% + 600px);
}

.carousel .list .item:nth-child(n+7){
    left: calc(67% + 800px);
    opacity: 0;
}





.list .item .content{
    position: absolute;
    top: 40%;
    left: 100px;
    transform: translateY(-50%);
    width: 55%;
    text-align: left;
    color: #fff;
    display: none;
}

.list .item:nth-child(2) .content{
    display: block;
}

.content .title{
    font-size: 100px;
    text-transform: uppercase;
    color: #14ff72cb;
    font-weight: bold;
    line-height: 1;
    opacity: 0;
    animation: animate 1s ease-in-out 0.3s 1 forwards;
}

.content .name{
    font-size: 50px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
    color:#feab3a;
    text-shadow: 4px 3px 0px #fff, 9px 8px 0px rgba(0,0,0,0.15);
    font-family: 'SuisseInt1';
    opacity: 0;
    animation: animate 1s ease-in-out 0.6s 1 forwards;
    
}

.content .des{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    margin-left: 5px;
    color:white;
    opacity: 0;
    animation: animate 1s ease-in-out 0.9s 1 forwards;
    text-indent: 20px;
    width:100%;
    font-family: "Roboto", sans-serif;
    text-shadow: .1em .1em 0 hsl(200 50% 30%);
}

.content .btn{
    margin-left: 5px;

    opacity: 0;
    animation: animate 1s ease-in-out 1.2s 1 forwards;
}

.content .btn button{
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border: 2px solid #fff;
    color:orange;
}

.content .btn button:nth-child(1){
    margin-right: 15px;
}



@keyframes animate {
    
    from{
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
    }

    to{
        opacity: 1;
        transform: translate(0);
        filter: blur(0);
    }
}

/* Carousel */






/* next prev arrows */

.arrows{
    position: absolute;
    top: 80%;
    right: 52%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.arrows button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color:#feab3a;
    color: #fff;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}

.arrows button:hover{
    background: #fff;
    color: #000;
}


/* time running */
.carousel .timeRunning{
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 4px;
    background-color: #14ff72cb;
    left: 0;
    top: 0;
    animation: runningTime 7s linear 1 forwards;
}

@keyframes runningTime {
    
    from{width: 0%;}
    to{width: 100%;}

}


/* Responsive Design Adjustments */
@media screen and (max-width: 999px) {
    .list .item .content {
        left: 50px;
    }

    .content .title,
    .content .name {
        font-size: 70px;
    }

    .content .des {
        font-size: 16px;
    }
}

@media screen and (max-width: 690px) {
    /* Adjust title and description font size instead of hiding */
    .content .title {
        font-size: 30px; /* Smaller font for mobile */
        line-height: 1.2;

    }

    .content .name {
        font-size: 24px;
        width: 90%;
        text-shadow: 2px 2px 0px #fff, 5px 4px 0px rgba(0,0,0,0.15);
    }

    .content .des {
        font-size: 14px; /* Smaller font for description on mobile */
    }

    .content .btn button {
        padding: 8px 12px;
        font-size: 12px;
    }
    .list .item .content{
        top:38%;
        left:5%;
        width: 90%;
    }
}
