@import url('https://fonts.googleapis.com/css2?family=Geist+Mono:wght@100..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.workcontainer {

    border-radius: 8px;
    padding: 25px;
}
.services-container {
    background-color: #fdd835; /* Yellow background */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;


  }
  
  .services-item {
    align-items: center;
    justify-content: space-between;
    transition: color 0.3s ease-in-out;
    display: flex;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

  .services-id{
    font-family: "Geist Mono", monospace;
    font-size: 1.5rem !important;
    flex: 0 0 50px; /* Ensures ID stays on the left */
  text-align: center;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  transition: color 0.3s ease-in-out;
  }
  .services-title {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .services-text {
    font-size: 1.2rem;
    color: #555;
    padding-left: 15px;
    word-wrap: break-word;
    font-family: "Roboto", sans-serif;
    transition: color 0.3s ease-in-out;
  }

  .services-item:hover .services-id,
  .services-item:hover .services-text {
    color: #fdd835; /* Yellow color on hover */
  }
  
  

  