.needsbg{
    background-image:url('https://th.bing.com/th/id/R.45e8c500ead8fe9308e7684c920ad8b4?rik=Qf%2bSkS96iEBmzg&riu=http%3a%2f%2fntrustis.com%2fimg%2fbanner-cover-2.jpg&ehk=3HLOHrDIAv03Xl%2bShuxxqYl8lVAhHW%2fokJvWbiN8psI%3d&risl=&pid=ImgRaw&r=0') ;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    height:40vh;
    position: relative;
    width:100%;
}

.needcontent{
    position: absolute;
    top:50%;
    left:50%;
  transform: translate(-50%, -50%);
    width:60% !important;
}

@media (max-width: 600px) {
    .needsbg{
        width: 100%;
        height: 60vh;
    }
    .needcontent{
        width: 90% !important;

        
    }
}

.needhead{
    color: #f35626;
    background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: hue 10s infinite linear;
}

.needpara{
    color:white;
    font-size: 1.1rem;
}