@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Akaya+Kanadaka&family=Itim&family=Merienda:wght@300..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Nunito:ital,wght@1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.sapcontent{
    background-color: #f9eee3;
    padding: 20px;
    border-radius: 12px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

@media (min-width: 992px) {
    .sapcontent{
    padding-left: 50px !important;
    padding-right: 50px !important;
    }
}

.saptitle{
    font-family: "Jost", sans-serif;
    background: -webkit-linear-gradient(transparent, transparent), url(https://st2.depositphotos.com/1747568/11982/v/950/depositphotos_119826156-stock-illustration-abstract-background-consisting-of-yellow.jpg) repeat;
    background-size: 200px auto;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation: bgslide 10s infinite linear;
    font-size: 3.2rem;
    font-weight: 300px;
}
    @keyframes bgslide {
        from {
          background-position: 0 -200px;
        }
        to {
          background-position: 0 0%;
        }
      }
.sappara{
    font-size: 1.2rem;
    text-indent: 35px;
    text-align: left;
    font-family: "Roboto", sans-serif;
}
.erpb{
    background-image: url('https://image-analyzer.com/hubfs/Imported_Blog_Media/Screenshot-2019-10-16-at-10_18_21-3.jpg');
    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.erptitle{
    text-align: left;
    font-family: "Jost", sans-serif;
    background: -webkit-linear-gradient(transparent, transparent), url(https://wallpapercave.com/wp/wp4013627.jpg) repeat;
    background-size: 200px auto;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation: bgslide 10s infinite linear;
    font-size: 3.2rem;
    font-weight: 300px;
}

.erppara{
    font-size: 1.3rem;
    text-align: left;
    text-indent: 20px;
    font-family: "Roboto", sans-serif;
}

.img-erp{
    height:42vh;
    width:70%;
    
}
.img-shadow{
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 12px;
    transition: transform 0.3s ease , box-shadow 0.3s ease;
}

.img-shadow:hover{
    transform: translateX(-10px) translateY(-10px) scale(1.03);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}


@media (max-width: 768px){
    .img-erp{
        width:100%;
    }
    ul li{
        font-size: 1rem !important;
    }
}

.erpul {
    list-style: none; /* Removes default bullets */
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, auto); /* Creates two columns */
    gap: 10px 20px; /* Adds space between rows and columns */
}

.erpul li {
    position: relative;
    padding-left: 15px; /* Adds space for custom bullet */
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
    color: #333;
    transition: color 0.3s ease;
}

.erpul li::before {
    content: ""; /* Custom circular bullet */
    position: absolute;
    left: 0;
    top: 15px; /* Centers the bullet vertically */
    width: 8px;
    height: 8px;
    border-radius: 50%; /* Makes it circular */
    background-color: #555; /* Default bullet color */
    transition: background-color 0.3s ease;
    animation: pulse 1.5s infinite;
}

/*animation for ul */

@keyframes pulse {
    0% {
      transform: scale(1) translateY(-50%);
      opacity: 1;
    }
    50% {
      transform: scale(1.5) translateY(-50%);
      opacity: 0.5;
    }
    100% {
      transform: scale(1) translateY(-50%);
      opacity: 1;
    }
  }

.erpul li:hover::before {
    background-color: orange; /* Changes bullet color on hover */
}

.erpul li:hover{
    color:orange;
}
