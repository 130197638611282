.webpoints{
    text-align: start;
    
    border-radius: 15px;

}

.websub{
    grid-template-columns: repeat(1, auto) !important;
}

