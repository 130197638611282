/* Container for grid layout */
@import url('https://fonts.googleapis.com/css2?family=Itim&family=Nunito:ital,wght@1,200..1000&family=Raleway:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.card-wrapper {
  position: relative;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.fade-out-left {
  transform: translateX(-100%);
  opacity: 0;
}

.fade-in-right {
  transform: translateX(100%);
  opacity: 0;
}

.card-wrapper.fade-in-right {
  transform: translateX(0);
  opacity: 1;
}

.cardcontainer {
    display: grid;
    gap: 10px;
    padding: 20px;
    background-color: #f9fbfc;
    border-radius: 15px;
  }

  .row{
    justify-content:space-evenly;
  }
  
  .heading {
    font-family: "Jost", sans-serif;
    font-size: 2.8rem;
 
    letter-spacing: 2px;
    text-align: center;
    color: #f35626;
    background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: hue 10s infinite linear;
      margin-bottom: 10px !important;
      border-bottom: 2px solid orange;
      width:30%;
      margin: 0 auto;
  }
  

  

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}


.card1-title {
  font-size: 1.5em;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 0.5em;
  font-family: 'SuisseInt1';
  color:#ff9a3c;
  text-align: left;
  margin-left: 10px;
  border-bottom: 2px solid #ff9a3c;
  width: fit-content;
}

.small-desc {
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.5em;
  color: #452c2c;
  text-align: left;
  text-indent: 40px;
  font-family: "Roboto", sans-serif;
}



.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(179.5deg, rgb(255, 230, 69) 3.3%, rgb(255, 157, 73) 96%);
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: flex;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 2em 1.2em;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  border:1px solid orange;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.card1:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: radial-gradient(circle at 10% 20%, rgb(255, 200, 124) 0%, rgb(252, 251, 121) 90%);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}

.card1:hover:before {
  transform: scale(50);
}

.card1:hover .small-desc {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover .card-title {
  transition: all 0.5s ease-out;
  color: black !important;
}
.cardimg{
  width:180px;
  height:180px;
}

@media (max-width: 768px) {
  .cardimg{
    width: 75px !important;
    height: 75px !important;
    
}
.heading{
  width:90%;
  font-size: 2rem;
  font-family: "Jost", sans-serif !important;
}
.small-desc {
  font-size: 0.8rem;
  text-indent: 10px;
}
}


.custom-btn-card {
  --color: #ff9a3c;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
  background-color: white;
}

.custom-btn-card:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.custom-btn-card:hover {
  color: #fff;
  
}

.custom-btn-card-active{
  animation: activeeffect 0.3s forwards;
}

@keyframes activeeffect{
  0% {
    transform: translateY(0px);
  }
  100%{
    transform:translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
}

.custom-btn-card:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.custom-btn-card:hover:before {
  top: -30px;
  left: -30px;
}

.custom-btn-card:active:before {
  background: #3a0ca3;
  
}
