

.reason-card {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;

  padding: 2em 0;
  background: #FFF;
  box-shadow: 0 0 6px 0 rgba(32, 32, 36, 0.12);
  transition: all 0.35s ease;
  margin: 8px;
  
}

.reason-card::before, .reason-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(254, 171, 58);
  height: 4px;
}

.reason-card::before {
  width: 0;
  opacity: 0;
  transition: opacity 0 ease, width 0 ease;
  transition-delay: 0.5s;
}

.reason-card::after {
  width: 100%;
  background: white;
  transition: width 0.5s ease;
}

.reason-card .content {
  width: 18em;
  max-width: 80%;
}

.reason-card .logo {

  transition: all 0.35s ease;
  color: orange;
  font-weight: bold;
  text-align: start;
  font-size: 2rem;

}

.reason-card .h6 {
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
}

.reason-card .hover_content {
  overflow: hidden;
  max-height: 0;
  transform: translateY(1em);
  transition: all 0.55s ease;
}

.reason-card .hover_content p {
  margin: 1.5em 0 0;
  color: #6E6E70;
  line-height: 1.2em;
  text-align: left;
  text-indent: 40px;
}

.reason-card:hover {
  width: 24em;
  box-shadow: 0 10px 20px 0 rgba(32, 32, 36, 0.12);
}

.reason-card:hover::before {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease, width 0.5s ease;
  transition-delay: 0;
}

.reason-card:hover::after {
  width: 0;
  opacity: 0;
  transition: width 0 ease;
}

.reason-card:hover .logo {
  margin-bottom: 0.5em;
}

.reason-card:hover .hover_content {
  max-height: 16em;
  transform: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .reason-card {
    width: 100%;
    max-width: 100%;
  }

  .reason-card:hover {
    width: 100%;
  }
}