@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.reporttitle{
    font-size:1.5rem;
    text-align: left;
    margin-bottom: 8px;
    font-family: "Jost", sans-serif;
    
}

.reportpara{
    font-size: 1.2rem;
    text-align: left;
    font-family: "Roboto", sans-serif;
    text-indent: 30px;
}
.reportbg{
    background-color: #fff3ed;
}